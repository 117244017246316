// Suggested location to add your overrides so that migration would be easy by just updating the SASS folder in the future
html, body {
    overflow: unset !important;
}

#zmmtg-root, .meeting-app, .meeting-client, .meeting-client-inner  {
    z-index: 999 !important;
    background: #222 !important;
}


#zmmtg-root {
.main-content {
    height: unset !important;
    justify-content: unset !important;
    margin-left: 90px;
    margin-top: 90px;
    background: #222 !important;
}

.meeting-title {
    display: none;
}

    .video-share-layout {
        margin: 12px;
        margin-top: 90px !important;
        margin-left: 90px !important;
        width: 35vw !important;
        height: calc(35vw * 0.56) !important;
        

        canvas {
            width: 1000px !important;
            height: 393px !important;
        }

        .video-avatar__avatar-name {
            font-size: 30px !important
        }

        video {
            width: 100% !important;
            height: auto !important;
        }
    }

}

.meeting-info-container, .suspension-window-container {
    // display: none !important;
    z-index: 999 !important;
}